.box-rankingclientecsat{
    h3{
        font-size: 20px;
    }
    &-topinfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        label{
            color: var(--grey-300);
            margin: 0;
        }
        >div{
            display: flex;
            align-items: center;
        }
        &-select{
            margin-right: 20px;
            .form-select-box{
                display: flex;
                align-items: center;
                >div{
                    margin-left: 5px;
                    // max-width: 150px;
                }
            }
        }
        &-avaliacao{
            label{
                margin-right: 5px;
            }
            strong{
                margin-left: 5px;
            }
        }
    }
    &-graficos{
        padding: 20px;
        position: relative;;
        &-chart{
            height: 500px;
            &-info{
                position: absolute;
                bottom: 35px;
                span{
                    font-size: 13px;
                    margin-right: 10px;
                }
            }
        }
    }
    &-analise-grafica{
        padding: 20px;
        p{
            color: var(--grey-300);
            font-size: 14px;
            line-height: 24px;
        }
    }

}



@media (max-width: 779px) {
    .box-rankingclientecsat{
        &-topinfo{
            flex-direction: column;
            &-select{
                .form-select-box{
                    flex-direction: column;
                }
            }
        }
    }
}